#mobile-logo {
  margin-top: -8px;
  height: 35px;
}

#mobile-login-wrapper {
  margin-top: 55px;
}

#flashMessage {
  background: #006699;
  height: 40px;
  width: 100%;
  padding: 10px;
  position: absolute;
  z-index: 10;
  top: 52px;
  left: 0;
  color: #fff;
}

#flashMessage.error {
  background: #C73E3E;
}

.flashLogin #flashMessage {
  top: 0;
}

#mobile_content_wrapper #dvLoading img {
  margin: 5px;
  display: none;
}

.navbar-inverse {
  border-radius: 0;
  background: #83BF00;
  border-color: #83BF00;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand>img {
  height: inherit;
}

.navbar-inverse .navbar-toggle {
  border: none;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background: transparent;
}

.navbar-inverse .navbar-nav li a {
  color: #fff;
}

.navbar-inverse .navbar-nav li.active a {
  background: #83BF00;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.background.spring {
  background: url(/img/bg-image-lente.jpg), url(/img/bg-image.jpg) center no-repeat;
  background-size: cover;
}
.background.summer {
  background: url(/img/bg-image-zomer.jpg), url(/img/bg-image.jpg) center no-repeat;
  background-size: cover;
}
.background.autumn {
  background: url(/img/bg-image-herfst.jpg), url(/img/bg-image.jpg) center no-repeat;
  background-size: cover;
}
.background.winter {
  background: url(/img/bg-image-winter.jpg), url(/img/bg-image.jpg) center no-repeat;
  background-size: cover;
}

/* temp */
.panel-primary {
  border-color: #83BF00;
}

.panel-primary .divider {
  margin: 20px 0;
  height: 5px;
  background-color: #83BF00;
}

.panel-primary a, .panel-primary a:link {
  color: #83BF00;
}

.panel-primary a:hover {
  background: transparent;
  color: #83BF00;
}

.panel-primary > .panel-heading {
  background-color: #83BF00;
  border-color: #83BF00;
}

.panel-title small {
  color: #fff;
}

.panel-primary .value {
  margin-left: 22px;
  padding-left: 22px;
}

.btn.btn-primary {
  background: #83BF00;
  border-color: #83BF00;
}

.btn.btn-success {
  background: #009933;
  border-color: #009933;
}

.error-message {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.add-leave-request .form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.add-leave-request .form-control.form-date {
  margin-bottom: 10px;
}

.add-leave-request .form-control.form-time {
  width: 41.66666667%;
}

.add-leave-request .form-group .dot {
  text-align: center;
}

.add-leave-request h2 {
  margin-top: 0;
}

.ui-datepicker tbody td {
  background: #ddd;
}
